<template>
  <div class="products-page" data-v-step="23">
    <Guard permission="products.create" v-slot="{ isAvailable }">
      <IconButton v-if="isAvailable" icon="plus" isRaised @click="goToCreation">
        Create product
      </IconButton>
    </Guard>
    <div class="products-list-wrapper">
      <Title class="mb-40">Products</Title>
      <div data-v-step="25">
        <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
        <div v-else-if="!products.length" class="empty">
          You haven't created products yet
        </div>
        <template v-else>
          <template v-if="activeProducts.length">
            <p class="subtitle">Active</p>
            <ProductsList
              :products="activeProducts"
              :menuOptions="activeMenuOptions"
              @context-menu="handleContextMenu"
              @click="redirectToEdit"
            />
          </template>
          <div
            v-if="activeProducts.length && pausedProducts.length"
            class="divider mt-24 mb-16"
          />
          <template v-if="pausedProducts.length">
            <p class="subtitle">Paused</p>
            <ProductsList
              :products="pausedProducts"
              :menuOptions="pausedMenuOptions"
              @context-menu="handleContextMenu"
              @click="redirectToEdit"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { generateWidgetCode, generateWidgetPreviewLink } from "@/helpers/utils";
import { PRODUCT_MENU_OPTION_VALUES } from "@/helpers/mocks";
import ProductsList from "@/components/products/ProductsList";
import alert from "@/plugins/alert";
import dialog from "@/plugins/dialog";
import Guard from "@/components/common/Guard";
import { WidgetPositionEnum } from "@/helpers/enums";

export default {
  name: "ProductsPage",
  components: { Guard, ProductsList },
  data() {
    return {
      isLoading: false,
      isShowCodeAlert: false,
      codeAlertTimeout: null,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.products.list,
      selectedVenue: (state) => state.venues.selectedVenue,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    activeProducts() {
      return this.products.filter((product) => !product.isPaused);
    },
    pausedProducts() {
      return this.products.filter((product) => product.isPaused);
    },
    menuOptions() {
      return [
        {
          value: PRODUCT_MENU_OPTION_VALUES.EDIT,
          name: this.checkPermission("products.edit") ? "Edit" : "View",
        },
        {
          value: PRODUCT_MENU_OPTION_VALUES.COPY,
          name: "Copy widget code",
        },
      ].filter(Boolean);
    },
    activeMenuOptions() {
      return [
        ...this.menuOptions,
        {
          value: PRODUCT_MENU_OPTION_VALUES.PREVIEW,
          name: "Copy widget preview link",
        },
        this.checkPermission("products.pause") && {
          value: PRODUCT_MENU_OPTION_VALUES.PAUSE,
          name: "Pause",
        },
      ].filter(Boolean);
    },
    pausedMenuOptions() {
      return [
        ...this.menuOptions,
        this.checkPermission("products.pause") && {
          value: PRODUCT_MENU_OPTION_VALUES.ACTIVATE,
          name: "Make active",
        },
        this.checkPermission("products.delete") && {
          value: PRODUCT_MENU_OPTION_VALUES.DELETE,
          name: "Delete",
        },
      ].filter(Boolean);
    },
  },
  watch: {
    codeAlertTimeout(_, oldVal) {
      if (oldVal) {
        clearTimeout(oldVal);
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchProducts();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      updateProduct: "products/updateProduct",
      deleteProduct: "products/deleteProduct",
    }),
    async redirectToEdit(id) {
      await this.$router.push({
        name: "EditProduct",
        params: {
          id,
        },
      });
    },
    async handleContextMenu({ option, product }) {
      switch (option.value) {
        case PRODUCT_MENU_OPTION_VALUES.EDIT:
          await this.redirectToEdit(product.id);
          break;
        case PRODUCT_MENU_OPTION_VALUES.COPY:
          await navigator.clipboard.writeText(
            generateWidgetCode({
              venueId: this.selectedVenue.id,
              productId: product.id,
              position: WidgetPositionEnum.EMBEDDED,
              isPromocodesAvailable: true,
              widgetTheme: this.selectedVenue.widget,
            })
          );
          alert.open({ message: "Code copied" });
          break;
        case PRODUCT_MENU_OPTION_VALUES.PREVIEW:
          await navigator.clipboard.writeText(
            generateWidgetPreviewLink({
              venueId: this.selectedVenue.id,
              productId: product.id,
              isPromocodesAvailable: true,
            })
          );
          alert.open({ message: "Preview link copied" });
          break;
        case PRODUCT_MENU_OPTION_VALUES.DELETE:
          await this.handleProductDelete(product.id);
          break;
        default:
          await this.toggleProductActivity(
            product,
            option.value === PRODUCT_MENU_OPTION_VALUES.PAUSE
          );
      }
    },
    async toggleProductActivity(product, isPaused) {
      const confirmOptions = isPaused
        ? {
            title: "Pause product?",
            message: "It will not be displayed on the site",
            okText: "Pause",
          }
        : {
            title: "Make product active?",
            message: "It will appear on the site",
            okText: "Make active",
          };
      const confirmed = await dialog.confirm({
        ...confirmOptions,
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isLoading = true;
          await this.updateProduct({
            id: product.id,
            data: {
              isPaused,
            },
          });
          await this.fetchProducts();
        } finally {
          this.isLoading = false;
        }
      }
    },
    async handleProductDelete(id) {
      const confirmed = await dialog.confirm({
        title: "Delete product?",
        message: "This action cannot be undone",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isLoading = true;
          await this.deleteProduct({
            id,
          });
          await this.fetchProducts();
        } finally {
          this.isLoading = false;
        }
      }
    },
    goToCreation() {
      this.$router.push({
        name: "CreateProduct",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.products-page {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: $media-laptop) {
    gap: 64px;
  }

  .products-list-wrapper {
    display: flex;
    flex-direction: column;

    .subtitle {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;
    }
  }
}
</style>
