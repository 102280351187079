<template>
  <div class="grid-2">
    <ProductCard
      v-for="product in products"
      :key="product.id"
      :name="product.name"
      :title="product.title"
      :description="product.description"
      :image="product.image"
      :menuOptions="menuOptions"
      :isDeposit="false"
      @context-menu="$emit('context-menu', { option: $event, product })"
      @click="$emit('click', product.id)"
    />
  </div>
</template>

<script>
import { Components } from "@zteam/booking-axe-sb";

export default {
  name: "ProductsList",
  components: { ProductCard: Components.ProductCard },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
